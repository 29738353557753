import * as React from 'react'

import EspaceLayout from '../components/espaceLayout'
import museePoitiers from '../assets/img/musee-ste-croix-poitiers.jpg'

const PoitiersPage = () => {
  return (
    <EspaceLayout nomVille={process.env.poit} imageSrc={museePoitiers}>
      <p>Le musée Sainte-Croix est le plus grand musée de la ville de Poitiers. 
         Construit en 1974 sur les plans de l'architecte poitevin Jean Monge, il se dresse à la place de l'ancienne abbaye Sainte-Croix,
         aujourd'hui à Saint-Benoît.</p>
    </EspaceLayout>
  )
}

export default PoitiersPage
